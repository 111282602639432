.h1 {
  font-size: 60px;
  line-height: 70px;
  letter-spacing: -0.005em;
}

.h2 {
  font-size: 48px;
  line-height: 56px;
}

.h3 {
  font-size: 34px;
  line-height: 40px;
  letter-spacing: 0.0025em;
}

.h4 {
  font-size: 24px;
  line-height: 28px;
}

.h5 {
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.0015em;
}

.subtitle1 {
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.0015em;
}

.subtitle2 {
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.01em;
}

.body1 {
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.005em;
}

.body2 {
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.0025em;
}

.body3 {
  font-size: 16px;
  line-height: 24px;
}

.caption {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.004em;
}

.overline {
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.015em;
}

.button-large {
  font-size: 24px;
  font-weight: 700 !important;
  line-height: 28px;
  letter-spacing: 0.01em;
}

.button-medium {
  font-size: 16px;
  font-weight: 700 !important;
  line-height: 18px;
  letter-spacing: 0.01em;
}

.button-small {
  font-size: 12px;
  font-weight: 700 !important;
  line-height: 14px;
  letter-spacing: 0.01em;
}

.light {
  font-weight: 300;
}

.normal {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.semi-bold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.open-sans {
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.nunito {
  font-family: "Nunito", -apple-system, BlinkMacSystemFont, Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
